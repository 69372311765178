<template>
    <div>
        <vue-breadcrumbs :breadCrumbs='breadCrumbs'/>
        <select-route v-if="ready" :item='item' :hideActions='true'/>
        <v-expansion-panels v-if="ready"  v-model="panel" multiple>
            <v-expansion-panel
                v-for="(railway, idx) in item.route_railways" 
                :key="railway.id"
                class="mb-3"
                readonly
            >
                <v-expansion-panel-header  class="panel_header" hide-actions>
                    <div class="description">
                        <p><span>{{railway.railway.railway_number}}</span> {{railwayType(railway.railway.railway_type)}}</p>
                        <p @click="onToggle(railway, idx)">Выбрать место <img :class="{rotateChev: panel.includes(idx)}" src="@/assets/images/ui/down.svg" alt=""> </p>
                        <p>Свободных мест: {{railway.free_seat_places}}</p>
                        <div class="price">
                            <p>Цена за всех</p>
                            <p>{{railway.total_price}} KZT</p>
                        </div>
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="panel_content">
                    <train-model 
                        :type='railway.railway.railway_type' 
                        :limit='limit()' 
                        :clickedSeats='clickedSeats'
                        :selectable='true' 
                        @onClick='onClick'
                        ref="trainModel" 
                        class="mt-4 pb-4 train_model"
                    />
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-btn
            width="160"
            height="48"
            color="primary"
            @click="submitHandler"
        >
            Выбрать
        </v-btn>
    </div>
</template>

<script>
import VueBreadcrumbs from '@/components/ui/vueBreadcrumbs.vue'
import SelectRoute from '@/components/trains/selectRoute.vue'
import TrainModel from '@/components/trains/trainModel.vue'
import { trainService } from '@/services/trains.service.js'

export default {
    components: {
        TrainModel,
        VueBreadcrumbs,
        SelectRoute
    },
    data: () => ({
        clickedSeats: [],
        panel: [],
        station: null,
        ready: false,
        item: {}
    }),
    computed: {
        breadCrumbs() {
            let firstQuery = {
                adult: this.$route.query.adult,
                child: this.$route.query.child,
                pensioner: this.$route.query.pensioner,
                from_date: this.$route.query.from_date,
                to_date: this.$route.query.to_date,
                from_location: this.$route.query.from_location,
                to_location: this.$route.query.to_location,
            }
            return [
                {
                    text: 'Бронирование поездов',
                    to: `/booking/trains/`,
                    query: firstQuery
                },
                {
                    text: 'Выбор места',
                    to: `/booking/trains/rt/${this.$route.params.id}`,
                    query: { ...firstQuery, route_station: this.$route.query.route_station }
                },
                {
                    text: 'Обратно',
                    to: `/booking/trains/rt/${this.$route.params.id}/search/`,
                    query: { ...firstQuery, route_station: this.$route.query.route_station }
                },
                {
                    text: 'Выбор места',
                    to: `/booking/trains/rt/${this.$route.params.id}/search/${this.$route.params.second}`,
                },
            ]
        }
    },
    created() {
        this.getItems()
    },
    methods: {
        onClick(seat) {
            if (this.clickedSeats.length) {
                if (!this.clickedSeats.map(el => el.item.railway).includes(seat.item.railway)) {
                    this.clickedSeats = []
                }
            }
            if (this.clickedSeats.map(el => el.item.id).includes(seat.item.id)) {
                this.clickedSeats = this.clickedSeats.filter(el => el.item.id != seat.item.id)
                return
            }
            this.clickedSeats.push(seat)
            if (this.limit() < this.clickedSeats.length) {
                this.clickedSeats.shift()
            }
        },
        railwayType(type) {
            let options = {
                sedentary: 'Сидячий',
                coupe: 'Купе',
                reserved: 'Плацкарт',
                luxury: 'Люкс',
                grandee: 'Гранд',
            }
            return options[type]
        },
        async onToggle(item, idx) {
            const { results } = await trainService.getSingleActiveTrainsPlaces(item.id)
            if (this.panel.includes(idx)) {
                this.panel = this.panel.filter(el => el != idx)
            } else {
                this.panel.push(idx)
            }
            this.$store.commit('trainModel/SET_TRAIN_FROM_BACK', { type: item.railway.railway_type, railway: results})
        },
        limit() {
            return Number(this.$route.query.adult) + Number(this.$route.query.child) + Number(this.$route.query.pensioner)
        },
        async submitHandler() {
            if (this.clickedSeats.length != this.limit()) {
                let message = 'Выберите ещё место'
                this.$store.commit('ui/SET_NOTIFICATION', {show: true, message, color: 'error'})
                return
            }
            try {
                this.$loading(true)
                const seat_places = this.clickedSeats
                const postData = []
                seat_places.forEach(el => {
                    if (!postData.map(el => el.id).includes(el.item.railway)) {
                        postData.push({
                            id: el.item.railway,
                            seat_places: [el.item.id]
                        })
                    } else {
                        postData.find(tmp => tmp.id == el.item.railway).seat_places.push(el.item.id)
                    }
                })
                postData.forEach(el => {
                    this.item.route_railways.forEach(tmp => {
                        if (tmp.railway.id == el.id) {
                            el.id = tmp.id
                        }
                    })
                })
                const postPromise = []
                postData.forEach(data => {
                    postPromise.push(trainService.bookTrainSeatPlaces(data))
                })
                await Promise.all(postPromise)
                let query = {
                    ...this.$route.query,
                    second_seats: seat_places.map(el => el.item.id).join(','),
                    second_railways: postData.map(el => el.id).join(',')
                }
                this.$router.push({
                    path: `/booking/trains/rt/${this.$route.params.id}/search/${this.$route.params.second}/booking`,
                    query
                })
            } catch(e) {
                console.error(e);
            } finally {
                this.$loading(false)
            }
        },
        async getItems() {
            try {
                this.$loading(true)
                this.ready = false
                let params = {
                    id: this.$route.params.second,
                    adult: this.$route.query.adult,
                    child: this.$route.query.child,
                    pensioner: this.$route.query.pensioner,
                    route_station: this.$route.query.route_station_sec,
                }
                const res = await trainService.getSingleActiveTrains(params)
                this.item = res
                this.ready = true
            } catch (e) {
                console.error(e);
            } finally {
                this.$loading(false)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.panel_header {
    cursor: default;
    .description {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: unset;
        p {
            margin-bottom: 0;
            &:nth-of-type(2) {
                cursor: pointer;
                img {
                    transition: .2s;
                }
                .rotateChev {
                    transform: rotate(180deg);
                }
            }
            span {
                color: #FF8413;
            }
        }
        .price {
            text-align: center;
            p {
                &:first-of-type {
                    font-size: 12px;
                    line-height: 14px;
                    color: rgba(2, 16, 17, 0.5);
                }
                &:last-of-type {
                    margin-top: 6px;
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 24px;
                    color: #FF8413;
                }
            }
        }
    }
}

.panel_content {
    overflow-x: auto;
}
</style>